import React from 'react';
import ModalVideo from 'react-modal-video';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import Link from '../../../core/Components/Link';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';
import ScrollAnimation from 'react-animate-on-scroll';

class FullWidthVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    const {
      title,
      description,
      image,
      videoId,
      hasFade,
      secondaryLink,
      link,
      ariaLabel,
    } = this.props.fields;
    const content = (
      <div className="video-banner">
        <Image field={image} />
      </div>
    );
    const contentBlock = (
      <div className="full-width-video">
        <div className="product-media-title">
          <Text field={title} tag="h2" />
        </div>
        <div className="product-media-banner">
          <div className="l-padding">
            {videoId && videoId.value ? (
              <React.Fragment>
                <button
                  className="video-modal-cta"
                  type="button"
                  onClick={() => this.setState({ isModalOpen: true })}
                  aria-label={ariaLabel ? ariaLabel.value : 'Watch this video'}
                >
                  {content}
                </button>
                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isModalOpen}
                  onClose={() => this.setState({ isModalOpen: false })}
                  videoId={videoId.value}
                  youtube={{
                    autoplay: 1,
                    enablejsapi: 1,
                    rel: 0,
                  }}
                />
              </React.Fragment>
            ) : (
              content
            )}
          </div>
        </div>
        <div className="product-media-description">
          <div className="content-container__grouper">
            <RichText field={description} className="content" />
            {link.value.href ? (
              <div className="cta-grouping">
                <React.Fragment>
                  <Link field={link} className="cta-button video-tertiary" />
                  <React.Fragment>
                    {secondaryLink.value.href ? (
                      <Link
                        field={secondaryLink}
                        className="cta-button secondary"
                      />
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                </React.Fragment>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
    return hasFade && hasFade.value ? (
      <ScrollAnimation
        animateIn="toFadeInUp"
        animateOnce
        delay={100}
        duration={0.5}
      >
        {contentBlock}
      </ScrollAnimation>
    ) : (
      contentBlock
    );
  }
}

FullWidthVideo.propTypes = PropTypes.SitecoreItem.inject({
  title: PropTypes.SingleLineText,
  description: PropTypes.RichText,
  image: PropTypes.Image,
  videoId: PropTypes.SingleLineText,
  hasFade: PropTypes.Checkbox,
  link: PropTypes.link,
  secondaryLink: PropTypes.link,
  ariaLabel: PropTypes.SingleLineText,
});

FullWidthVideo.defaultProps = DefaultProps.SitecoreItem.inject({
  title: DefaultProps.SingleLineText,
  description: DefaultProps.RichText,
  image: DefaultProps.Image,
  videoId: DefaultProps.SingleLineText,
  hasFade: DefaultProps.Checkbox,
  link: DefaultProps.link,
  secondaryLink: DefaultProps.link,
  ariaLabel: DefaultProps.SingleLineText,
});

export default FullWidthVideo;
